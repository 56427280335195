import React from 'react';

const getNavSchema = (menus) => {
	const menuSchemaGraph = [];
	const getSchemaNavElement = menu => ({
		'@type': 'SiteNavigationElement',
		'name': menu.title,
		'url': menu.url,
	});

	menus.forEach((menu) => {
		const {
			hiddenOnFull = false,
		} = menu;
		const shouldAddElement = !hiddenOnFull;

		if (shouldAddElement) { menuSchemaGraph.push(getSchemaNavElement(menu)); }

		if (menu.subMenuColumns) {
			menu.subMenuColumns.forEach((column) => {
				column.columnMenus.forEach((subMenu) => {
					subMenu.subMenuItems.forEach((item) => {
						if (shouldAddElement) { menuSchemaGraph.push(getSchemaNavElement(item)); }
					});
				});
			});
		}
	});

	return {
		'@context': 'http://schema.org',
		'@graph': menuSchemaGraph,
	};
};

class TopMenuSchema extends React.Component {
	render() {
		const { menuData } = this.props;

		return (
			<script className="schema-top-menu-data" dangerouslySetInnerHTML={{ __html: JSON.stringify(getNavSchema(menuData), null, 2) }} type="application/ld+json" />
		);
	}
}

export { TopMenuSchema };
