import React from 'react';

import { s7ContentPath } from '~/global/global.constants';
import styles from '#/the-callan-chair/the-callan-chair.module.scss';
import { LazyImage } from '~/components/static-slider/Components/LazyImage';

const renderImage = (src, srcSet, sizes = null) => {
	return (
		<LazyImage
			src={src}
			srcSet={srcSet}
			sizes={sizes}
			triggerLoad={false}
		/>
	);
};

const renderQuote = (quote) => {
	return (
		<div className={styles.CallanChair5Star}>
			<p className="Heading Heading--3 tw-text-white">&ldquo;{quote}&rdquo;</p>
			<img alt="" width="95" src={`${s7ContentPath}/5_stars_white`} />
		</div>
	);
};

const renderImageWithAttr = (src, srcSet, sizes = null, imageAttribute) => {
	return (
		<figure className="Card Card-footnote tw-p-0">
			<LazyImage
				src={src}
				srcSet={srcSet}
				sizes={sizes}
				triggerLoad={false}
			/>
			<figcaption className="Card-footnote--overlay tw-text-white">{imageAttribute}</figcaption>
		</figure>
	);
};

export const StaticSliderCallanFiveStar = ({ item }) => {
	const {
		imageAttribute,
		sizes,
		src,
		srcSet,
		quote
	} = item;
	return (
		<div>
			{quote && renderQuote(quote)}
			{(!imageAttribute && !quote) && renderImage(src, srcSet, sizes)}
			{imageAttribute && renderImageWithAttr(src, srcSet, sizes, imageAttribute)}
		</div>
	);
};
