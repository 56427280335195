import React from 'react';

import { isEngage } from '~/global/global.constants';
import { ClientOnly } from '~/util/Components/ClientOnly';

interface Props {
	fill?: string
	stroke?: string
	className?: string
	loggedIn?: boolean
}

export const AccountSvg = (props: Props) => {
	const {
		fill = 'currentColor',
		stroke = 'currentColor',
		className = 'hover-fill',
		loggedIn = false
	} = props;
	return (
		<>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="22.642"
				height="21.654"
				viewBox="0 0 22.642 21.654"
				focusable="false"
				className="md:tw-mb-2"
				aria-hidden
			>
				<g transform="translate(0.5 0.5)">
					<ellipse
						className={className}
						cx="5.761"
						cy="5.761"
						rx="5.761"
						ry="5.761"
						transform="translate(5.06)"
						fill={fill}
						stroke={stroke}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeMiterlimit="10"
						strokeWidth="1"
					/>
					<g transform="translate(0 12.502)">
						<path
							className={className}
							d="M23.642,22.666c0-2.314-4.206-4.907-5.656-4.907a10.29,10.29,0,0,1-5.165,1.3,10.29,10.29,0,0,1-5.165-1.3C5.721,17.759,2,20.136,2,22.666v3.245H23.642Z"
							transform="translate(-2 -17.759)"
							fill={fill}
							stroke={stroke}
							strokeMiterlimit="10"
							strokeWidth="1"
						/>
					</g>
				</g>
			</svg>
			<ClientOnly>
				{loggedIn && !isEngage &&
					<span className="tw-border tw-border-solid tw-border-white tw-bg-gray tw-w-5 tw-h-5
							tw-absolute tw-left-0 tw-bottom-[-6px] tw-flex tw-justify-center tw-items-center
							tw-rounded-full md:tw-left-[-2px] md:tw-bottom-[-2px]" data-qa="account-signed-in-icon">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="11"
							viewBox="0 0 12.14 10.25"
							focusable="false"
							role="img"
							aria-labelledby="signedInTitle"
						>
							<title id="signedInTitle">Signed In</title>
							<g>
								<polygon
									fill="white"
									points="4.46,9.17 0.86,5.57 2.28,4.16 4.46,6.34 9.75,1.04 11.17,2.46  "
								/>
							</g>
						</svg>
					</span>
				}
			</ClientOnly>
		</>
	);
};
