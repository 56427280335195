import React from 'react';
import classNames from 'classnames';

import styles from '../ButtonShopRoom.module.scss';
import shopRoomLinkStyles from '../../Components/ShopRoomLink.module.scss';

export const ButtonShopRoom = (props) => {
	const {
		posAbsRight = null,
		posAbsLeft = null,
		buttonText = 'Shop',
		shopLink,
		dataQA = 'shop-room-button',
		trLinkEventCompName = null,
		trLinkEventCompType = null,
	} = props;

	return (
		<a
			className={classNames({
				[styles.shopThisRoomLink]: true,
				[shopRoomLinkStyles.root]: true,
				[styles['abs-right']]: posAbsRight,
				[styles['abs-left']]: posAbsLeft,
				'tw-flex': true,
			})}
			data-tr-link-event-comp-name={trLinkEventCompName}
			data-tr-link-event-comp-type={trLinkEventCompType}
			data-qa={dataQA}
			href={shopLink}
		>
			<svg role="img" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" id="Layer_1" x="0" y="0" viewBox="0 0 24.77 24.44">
				<path d="M13.71 22.42L2.95 11.66l-1-4.79 5.13-5.13 4.75 1.04 10.75 10.76-8.87 8.88z" />
				<circle cx="6.62" cy="6.46" r="1.25" />
			</svg>
			<span className={styles.shopThisRoomLinkText}>{buttonText}</span>
		</a>

	);
};
