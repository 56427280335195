import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import listStyles from '#/mobile/global/menuList.module.scss';

import { s7ContentPath } from '~/global/global.constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';

import { TopMenuMobilePromoSlider } from '~/global/top-menu-mobile/Components/TopMenuMobilePromoSlider';

const TopMenuMobileSubmenuPrimary = observer((props) => {
	const { topMenuMobileStore } = useGlobalContext();

	const {
		index,
		menu: {
			isActive,
			subMenus = [],
			contentPromos,
			title = '',
			url = '',
		},
	} = props;

	useEffect(() => {
		if (isActive) {
			window.scrollTo(0, 0);
		}
	});

	return (
		<div data-submenu-index={index} data-qa={`mobile-submenu-${title}`} className="tw-bg-white" style={{ display: isActive ? 'block' : 'none' }}>
			<ul className={classNames(
				listStyles['menuList'],
				listStyles['subMenu-list']
			)}>
				<li data-nav-track="BACK">
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a
						id={`back-${title.toUpperCase()}`}
						className={listStyles['back-button']}
						data-mobile-submenu-back={title.toUpperCase()}
						data-tr-link-event-track={false}
						data-qa="mobile-nav-back-button"
						href="#"
						onClick={
							(event) => {
								event.preventDefault();
								topMenuMobileStore.deactivateMenus({ nextFocusSelector: `[data-mobile-submenu="${(title).toUpperCase()}"]` });
							}
						}
					>
						<span aria-hidden="true">
							<img src={`${s7ContentPath}/icon_backSecondarySubmenu_gray`} width="6" alt="" />
						</span>
						<span aria-label="Back to categories">Back</span>
					</a>
				</li>
			</ul>
			<TopMenuMobilePromoSlider title={title} url={url} contentPromos={contentPromos} />
			{
				subMenus.map((submenu, submenuGroupIndex) => {
					const {
						subMenuItems = [],
						classes = '',
						hideMobile,
						title: submenuTitle = '',
						url: submenuUrl = '',
					} = submenu;

					if (hideMobile) {
						return null;
					}

					return (
						<ul
							className={
								classNames({
									[listStyles['menuList']]: true,
									[classes]: classes && true,
								})
							}
							key={`submenuGroup-${submenuGroupIndex}`}
						>
							<li data-nav-track={submenuTitle}>
								{submenuTitle && (
									<>
										{!submenuUrl && (
											<span className={listStyles['link-list-heading']}>{submenuTitle}</span>
										)}
										{submenuUrl && (
											<a className={listStyles['link-list-heading']} href={submenuUrl}>{submenuTitle}</a>
										)}
									</>
								)}
								<ul className={classNames(
									listStyles['menuList'],
									listStyles['link-list-small']
								)}>
									{
										subMenuItems.map((submenuLink, submenuLinkIndex) => {
											const {
												title: linkTitle = '',
												url: linkUrl = '',
												hiddenOnMobile: linkHideMobile = '',
											} = submenuLink;

											if (linkHideMobile) {
												return null;
											}

											return (
												<li data-nav-track={linkTitle.toUpperCase()} key={`submenuLink-${submenuLinkIndex}`}>
													<a
														data-tr-link-event-comp-name={title}
														href={linkUrl}
														data-qa={`mobile-submenu-${title}-link-${linkTitle}`}
													>{linkTitle}</a>
												</li>
											);
										})
									}
								</ul>
							</li>
						</ul>
					);
				})
			}
		</div>
	);
});

export { TopMenuMobileSubmenuPrimary };
