import React from 'react';

import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';
import { LazyImage } from '~/components/static-slider/Components/LazyImage';
import { MagicHeaderTag } from '~/components/magic-header';

const renderCaption = (titleClass, title, titleHeaderTag, list, listClass) => {
	return (
		<>
			{title && (
				<MagicHeaderTag
					headerLevel={titleHeaderTag}
					className={titleClass || 'Heading Heading--4'}
				>
					{title}
				</MagicHeaderTag>
			)}
			{list &&
				<ul className={listClass || 'Body Body--2 tw-text-gray'}>
					{list.map((listItem, index) => {
						return (
							<li key={index}>{listItem}</li>
						);
					})}
				</ul>
			}
		</>
	);
};

const StaticSliderImageTitleList = ({ item }) => {
	const {
		titleClass = null,
		title = null,
		titleHeaderTag = 'h4',
		list = [],
		listClass = '',
		sizes = null,
		src,
		srcSet,
		imageWidth,
		imageClasses = null,
	} = item;
	return (
		<div>
			<ConditionalWrapper
				condition={imageClasses}
				wrapper={children => <div className={imageClasses}>{children}</div>}>
				<LazyImage
					src={src}
					srcSet={srcSet}
					sizes={sizes}
					triggerLoad={false}
					imageWidth={imageWidth}
				/>
			</ConditionalWrapper>
			{renderCaption(titleClass, title, titleHeaderTag, list, listClass)}
		</div>
	);
};

export {
	StaticSliderImageTitleList,
};
