/* eslint-disable import/no-default-export */

import React from 'react';

export const FavoritesLinkPlaceholder = () => {
	return (
		<a
			className="tw-hidden-for-print top-link favorites-items-status-link tw-flex tw-items-center"
			title="Favorites"
			data-tr-link-event-name="favorites icon"
			href="/favorites"
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="25.2" height="23.307" viewBox="0 0 25.2 23.307" focusable="false">
				<path className="hover-fill" d="M27.9,10.5a7.127,7.127,0,0,0-7.1-7.1,6.944,6.944,0,0,0-5,2.1,7.071,7.071,0,0,0-12.1,5,6.944,6.944,0,0,0,2.1,5h0l10,10,9.7-9.7A6.968,6.968,0,0,0,27.9,10.5Z" transform="translate(-3.2 -2.9)" fill="#333" stroke="#333" strokeMiterlimit="10" strokeWidth="1" />
			</svg>
			<span className="tw-sr-only">Favorites</span>
		</a>
	);
};

export default FavoritesLinkPlaceholder;
