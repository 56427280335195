import React from 'react';
import classNames from 'classnames';

import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';

export const StaticSliderLink = (props) => {
	const {
		link,
		linkClass,
		linkColor,
		linkWrapper,
		linkUseButtonStyle = false,
	} = props;

	const linkClasses = linkUseButtonStyle ?
		classNames(
			linkClass,
			linkColor || 'HollowButton--gray',
			'HollowButton  HollowButton--transparent',
		) :
		classNames(
			linkClass || 'Body Body--2',
			linkColor || 'tw-text-gray',
			{ 'tw-underline': linkWrapper },
			'tw-block tw-mb-3',
		);

	return (
		<ConditionalWrapper
			condition={!linkWrapper}
			wrapper={children => <a className={linkClasses} href={link.url}>{children}</a>} >
			<span className={!linkUseButtonStyle ? linkClasses : ''}>{link.copy}</span>
		</ConditionalWrapper>
	);
};
