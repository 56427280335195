'use client';

import dynamic from 'next/dynamic';
import React, { Suspense } from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { Header } from '~/global/header/Components/Header';
import { HeaderMobile } from '~/global/header-mobile/Components/HeaderMobile';
import { TopMenu } from '~/global/top-menu/TopMenu';
import { TopMenuMobile } from '~/global/top-menu-mobile/Components/TopMenuMobile';
import { isEngage } from '~/global/global.constants';
import { AccessibilityLinks } from '~/layout/Components/AccessibilityLinks';
import { SiteMarketingMessaging } from '~/site-marketing-messaging/Components/SiteMarketingMessaging';

import styles from '#/layout/main.module.scss';

const SiteMessaging = dynamic(() => import('~/global/site-messaging/SiteMessaging'), { ssr: true });

const FullHeader = () => {
	const { siteMarketingMessagingModel } = useGlobalContext();

	return (
		<div className={styles['entire-header-container']}>
			<AccessibilityLinks />
			<SiteMarketingMessaging siteMarketingMessagingModel={siteMarketingMessagingModel} />

			{/* DESKTOP HEADER */}
			<div className="tw-hidden tw-pt-[20px] md:tw-block print:tw-block">
				<div className={styles['header-container']}>
					<div>
						<Header />
					</div>
				</div>
				<div className={styles['dividers']}>
					<div className={styles['header-container']}>
						<TopMenu />
					</div>
				</div>
				<Suspense fallback={null}>
					<SiteMessaging />
				</Suspense>
			</div>
			{/* MOBILE HEADER */}
			<div className="md:tw-hidden">
				<HeaderMobile sticky={true} />
				<TopMenuMobile />
				{
					!isEngage && (
						<Suspense fallback={null}>
							<SiteMessaging />
						</Suspense>
					)
				}
			</div>
		</div>
	);
};

FullHeader.displayName = 'FullHeader';

export { FullHeader };
