import React, { ComponentProps } from 'react';

import { TabbedShoppingLink } from '~/components/tabbed-shopping/tabbed-shopping-link/TabbedShoppingLink';

interface IProps {
	item?: ComponentProps<typeof TabbedShoppingLink>;
	trLinkEventCompPosition: string
}

export const StaticSliderTabbedShoppingLink = (props: IProps) => {
	if (!props.item) {
		return null;
	}

	return (
		<TabbedShoppingLink {...props.item} trLinkEventCompPosition={props.trLinkEventCompPosition} />
	);
};
