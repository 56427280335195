import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import styles from '#/mobile/global/header.module.scss';

const HeaderMobileSearchButton = observer(class HeaderMobileSearchButton extends React.Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
	}

	render() {
		const {
			headerMobile: {
				isSearchOpen,
			}
		} = this.props;

		return (
			<button
				aria-controls="page-search"
				aria-label="Open search input"
				className={
					classNames({
						[styles['header-mobile-search-button']]: true,
						'has-menu': true,
						'has-focus': isSearchOpen,
					})
				}
				data-qa="search-button"
				id="search-button"
				onClick={this.handleClick}
			>
				<svg xmlns="http://www.w3.org/2000/svg" height="22" width="22" viewBox="2 2 16 16">
					<circle className={`${styles['no-fill']} st0`} cx="8.124" cy="7.717" r="4.7" fill="#fff" stroke="#333" strokeMiterlimit="10" strokeWidth="1" />
					<path className="st0" d="M11.167 11l5.683 5.684" stroke="#333" strokeMiterlimit="10" strokeWidth="1" />
				</svg>
			</button>
		);
	}

	handleClick() {
		const { clickHandler } = this.props;

		clickHandler();
	}
});

export { HeaderMobileSearchButton };
