import React from 'react';

import { LazyImage } from '~/components/static-slider/Components/LazyImage';
import { ButtonShopRoom } from '~/components/Buttons/ButtonShopRoom/Components/ButtonShopRoom';

const renderText = (text) => {
	return (
		<div className="textBox-280 tw-bg-gray-500 tw-flex tw-items-center tw-px-6">
			<p className="tw-text-gray tw-mb-0">{text}</p>
		</div>
	);
};

const renderImageWithShopBtn = (src, srcSet, sizes = null, shopLink, trLinkEventCompPosition) => {
	return (
		<div className="tw-relative" data-tr-link-event-comp-position={trLinkEventCompPosition}>
			<ButtonShopRoom shopLink={shopLink} posAbsLeft={true} trLinkEventCompPosition={trLinkEventCompPosition} />
			<LazyImage
				src={src}
				srcSet={srcSet}
				sizes={sizes}
				triggerLoad={false}
			/>
		</div>
	);
};

export const StaticSliderTopBaseSTR = ({ item, trLinkEventCompPosition = null }) => {
	const {
		shopLink,
		sizes,
		src,
		srcSet,
		text,
	} = item;
	return (
		<div>
			{text && renderText(text)}
			{shopLink && renderImageWithShopBtn(src, srcSet, sizes, shopLink, trLinkEventCompPosition)}
		</div>
	);
};
