import React from 'react';

import styles from '#/mobile/global/header.module.scss';

class HeaderMobileOverlay extends React.Component {
	constructor(props) {
		super(props);

		this.handleClick = this.handleClick.bind(this);
	}

	render() {
		return (
			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
			<div
				className={styles['header-mobile-overlay']}
				onClick={this.handleClick}
				onKeyUp={this.handleClick}
			/>
		);
	}

	handleClick() {
		const { clickHandler } = this.props;

		clickHandler();
	}
}

export { HeaderMobileOverlay };
