import React from 'react';
import ReactMarkdown from 'react-markdown';

import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';
import { LazyImage } from '~/components/static-slider/Components/LazyImage';
import { MagicHeaderTag } from '~/components/magic-header';

const renderCaption = (title, titleClass, titleHeaderTag, caption) => {
	if (typeof caption === 'function') {
		return (
			<>
				{title && (
					<MagicHeaderTag
						headerLevel={titleHeaderTag}
						className={titleClass || 'Heading Heading--4'}
					>
						{title}
					</MagicHeaderTag>
				)}
				<span dangerouslySetInnerHTML={{ __html: caption() }} />
			</>
		);
	}
	return (
		<>
			{title && (
				<MagicHeaderTag
					headerLevel={titleHeaderTag}
					className={titleClass || 'Heading Heading--4'}
				>
					{title}
				</MagicHeaderTag>
			)}
			<span>
				<ReactMarkdown components={{
					p: React.Fragment
				}}>
					{caption}
				</ReactMarkdown>
			</span>
		</>
	);
};

const StaticSliderImageText = ({ item }) => {
	const {
		title = null,
		titleClass = '',
		titleHeaderTag = 'h4',
		caption,
		sizes = null,
		src,
		srcSet,
		imageWidth,
		imageClasses = null,
	} = item;
	return (
		<div>
			<ConditionalWrapper
				condition={imageClasses}
				wrapper={children => <div className={imageClasses}>{children}</div>}>
				<LazyImage
					src={src}
					srcSet={srcSet}
					sizes={sizes}
					triggerLoad={false}
					imageWidth={imageWidth}
				/>
			</ConditionalWrapper>
			{caption &&
				renderCaption(title, titleClass, titleHeaderTag, caption)
			}
		</div>
	);
};

export {
	StaticSliderImageText,
};
