import React, { ComponentProps, PropsWithChildren } from 'react';

import { LinkBlock } from '~/components/Buttons/Components/LinkBlock';
import { LinkBlockTextLink } from '~/components/Buttons/Components/LinkBlockTextLink';
import { TextLinkColor } from '~/components/Buttons/Types/constants';
import { HomepageLinkCaption } from '~/components/homepage-link-list/homepage-link-caption/HomepageLinkCaption';

interface IProps extends ComponentProps<typeof HomepageLinkCaption> {
	className?: string;
	linkBlockClassName?: string,
	href: string;
	trLinkEventCompPosition?: string;
	trLinkEventCompType?: string;
}

export const HomepageLinkItem = (props: PropsWithChildren<IProps>) => {
	return (
		<LinkBlock
			data-tr-link-event-comp-position={props.trLinkEventCompPosition}
			data-tr-link-event-comp-type={props.trLinkEventCompType}
			href={props.href}
			className={props.className}
		>
			{props.children}
			<LinkBlockTextLink
				className={props.linkBlockClassName}
				color={TextLinkColor.Gray}
				hasNoUnderline={true}
			>
				<HomepageLinkCaption
					caption={props.caption}
					captionClassName={props.captionClassName}
					captionComponent={props.captionComponent}
					captionSize={props.captionSize}
				/>
			</LinkBlockTextLink>
		</LinkBlock>
	);
};
