import { action } from 'mobx';

import { SignInModalStoreFactory } from '~/account/sign-in/Stores/SignInModal.store';

export const openSignInModalHandler = action((viewKey: string = 'signIn', magicModal: any, globalDynamicStore: any, HREF: any) => {
	const signInModalStore = SignInModalStoreFactory
		.create(magicModal, globalDynamicStore, HREF);

	signInModalStore.openModal(viewKey, {
		callback: () => {
			magicModal.closeModal();
			window.location.reload();
		},
	});
});
