'use client';

import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

export const PreMenu = () => {
	const [expandedPreMenuId, setExpandedPreMenuId] = useState(null);

	useEffect(() => {
		// Make sure to close all menus opened by keyboard navigation when hitting esc (there should only be 1)
		document.addEventListener('keydown', (e) => {
			if (e.code === 'Escape') {
				setExpandedPreMenuId(null);
			}
		});
		const preHeader = document.querySelector('.pre-header-menu');
		preHeader.addEventListener('click', (e) => {
			const parent = e.target.closest('section');
			if (parent && expandedPreMenuId && !parent.classList.contains('pre-header-menu')) {
				setExpandedPreMenuId(null);
			}
		});
	});

	function setExpandedMenuId(id) {
		setExpandedPreMenuId(expandedPreMenuId === id ? null : id);
	}

	function onMenuFocus(e) {
		if (expandedPreMenuId !== e.target.getAttribute('data-menu-id')) {
			setExpandedPreMenuId(null);
		}
	}

	return (
		<nav
			aria-label="Secondary menu"
			className="tw-hidden-for-print pre-header-menu"
			data-tr-link-event-comp-name="preheader menu"
			data-tr-link-event-comp-type="header navigation"
		>
			<ul>
				<li>
					<a href="/free-design-services"
						data-qa="free-design-services-link"
						onFocus={e => onMenuFocus(e)}
						data-menu-id="preSubMenu">
						Free Design Services
					</a>
				</li>
				<li>
					<a href="/ideas-advice"
						data-qa="ideas-advice-link"
						onFocus={e => onMenuFocus(e)}
						data-menu-id="preSubMenu">
						<span>Ideas &amp; Advice</span>
					</a>
					<button
						aria-controls="preSubMenu-IA"
						onClick={() => { setExpandedMenuId('preSubMenu-IA'); }}
						onFocus={e => onMenuFocus(e)}
						aria-expanded={expandedPreMenuId === 'preSubMenu-IA'}
						className="openMenu"
						data-menu-id="IA"
					>
						<span className="tw-sr-only">{expandedPreMenuId === 'preSubMenu-IA' ? 'Close' : 'Open'} <span>Ideas &amp; Advice</span> menu</span>
					</button>

					<div
						className={
							classNames('pre-header-subMenu', {
								'showMenu': expandedPreMenuId === 'preSubMenu-IA',
							})
						}
						id="preSubMenu-IA"
					>
						<ul>
							<li>
								<a href="/ideas-advice/get-inspired" data-qa="get-inspired-link">Get Inspired</a>
							</li>
							<li>
								<a href="/ideas-advice/explore-materials" data-qa="explore-materials-link">Explore Materials</a>
							</li>
							<li>
								<a href="/ideas-advice/design-your-space" data-qa="design-your-space-link">Design Your Space</a>
							</li>
							<li>
								<a href="/ideas-advice/product-care" data-qa="product-care-assembly-safety-information-link">Product Care, Assembly &amp; Safety Information</a>
							</li>
							<li>
								<a href="/digital-catalogs" data-qa="digital-catalogs-link">Digital Catalogs</a>
							</li>
							<li>
								<a href="/ideas-advice/videos" data-qa="videos-link">Videos</a>
							</li>
						</ul>
					</div>

				</li>
				<li>
					<a href="/small-space"
						data-qa="small-space-link"
						onFocus={e => onMenuFocus(e)}
						data-menu-id="preSubMenu"
					>
						Small Space
					</a>
				</li>
				<li>
					<a href="/business"
						data-qa="business-interiors-link"
						onFocus={e => onMenuFocus(e)}
						data-menu-id="preSubMenu"
					>
						For Business
					</a>
				</li>
				<li>
					<a href="/sustainability"
						data-qa="sustainability-link"
						onFocus={e => onMenuFocus(e)}
						data-menu-id="preSubMenu"
					>
						Sustainability
					</a>
					<button
						aria-controls="preSubMenu-sustainability"
						onClick={() => { setExpandedMenuId('preSubMenu-sustainability'); }}
						onFocus={e => onMenuFocus(e)}
						aria-expanded={expandedPreMenuId === 'preSubMenu-sustainability'}
						className="openMenu"
						data-menu-id="sustainability"
					>
						<span className="tw-sr-only">{expandedPreMenuId === 'preSubMenu-sustainability' ? 'Close' : 'Open'} <span>Sustainability</span> menu</span>
					</button>

					<div
						className={
							classNames('pre-header-subMenu', {
								'showMenu': expandedPreMenuId === 'preSubMenu-sustainability',
							})
						}
						id="preSubMenu-sustainability"
					>
						<ul>
							<li>
								<a href="/sustainability/better-products" data-qa="better-products-link">Better Products</a>
							</li>
							<li>
								<a href="/sustainability/better-for-people" data-qa="better-for-people-link">Better for People</a>
							</li>
							<li>
								<a href="/sustainability/better-for-the-planet" data-qa="better-for-the-planet-link">Better for the Planet</a>
							</li>
							<li>
								<a href="/sustainability/guidelines-policies" data-qa="guidelines-policies-link">Guidelines &amp; Policies</a>
							</li>
							<li>
								<a href="/sustainability/impact-report" data-qa="impact-report-link">Impact Report</a>
							</li>
							<li>
								<a href="/sustainability/certified-b-corp" data-qa="bcorp-link">Certified B Corp</a>
							</li>
						</ul>
					</div>
				</li>
				<li>
					<a href="/gift-cards"
						data-qa="gift-cards-link"
						onFocus={e => onMenuFocus(e)}
						data-menu-id="preSubMenu"
					>
						Gift Cards
					</a>
				</li>
			</ul>
		</nav>
	);
};
