import React from 'react';

import themeConfig from '~/lib/tailwind.config.json';

type Props = {
	height?: React.CSSProperties['height']
	hoverFill?: boolean
};

const {
	theme: {
		colors: {
			gray: {
				DEFAULT: gray,
			},
			white: {
				DEFAULT: white,
			},
		},
	},
} = themeConfig;

export const MapPinSvg = (props: Props) => {
	const { height = '24.73px', hoverFill = false } = props;
	const pathClassName = hoverFill ? { className: 'hover-fill' } : null;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.37 23.88" style={{ height }}>
			<path
				{...pathClassName}
				d="M8.69.5a8.16 8.16 0 0 0-7 12.42c.13.2.26.4.4.6L8.45 23l6.76-9.4.32-.43A8.18 8.18 0 0 0 8.69.5Z"
				stroke={gray}
				fill={white}
			/>
			<circle
				cx="8.69"
				cy="8.68"
				r="4.09"
				stroke={gray}
				fill={white}
			/>
		</svg>
	);
};
