import React from 'react';

import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';
import { S7Image } from '~/components/Images/S7Image';
import { LazyImage } from '~/components/static-slider/Components/LazyImage';
import { MagicHeaderTag } from '~/components/magic-header';

const renderCaption = (title, titleClass, titleHeaderTag, caption, linkText, linkClass) => {
	if (typeof caption === 'function') {
		return (
			<>
				{title && <h4 className={titleClass || 'Heading Heading--4'}>{title}</h4>}
				<span dangerouslySetInnerHTML={{ __html: caption() }} />
			</>
		);
	}
	return (
		<>
			{title &&
				<MagicHeaderTag
					headerLevel={titleHeaderTag}
					className={titleClass || 'Heading Heading--4'}
				>{title}</MagicHeaderTag>
			}
			<span className="tw-text-gray">{caption}<br />
				{linkText && <span className={linkClass || 'tw-underline tw-text-blue'}>{linkText}</span>}
			</span>
		</>
	);
};

const StaticSliderImageTextLink = ({
	item, trLinkEventCompPosition = null, imageWidth = null, imageHeight = null, useS7Image = false,
}) => {
	const {
		src,
		srcSet,
		sizes,
		title = null,
		titleClass = null,
		caption,
		linkText = null,
		link,
		linkClass = null,
		imageClasses = null,
		titleHeaderTag = null,
	} = item;
	return (
		<a className="tw-no-underline"
			data-tr-link-event-comp-position={trLinkEventCompPosition}
			data-tr-link-event-name={linkText}
			href={link}>
			<ConditionalWrapper
				condition={imageClasses}
				wrapper={children => <div className={imageClasses}>{children}</div>}
			>
				{
					useS7Image
						? <S7Image
							alt=""
							height={imageHeight}
							sizes={sizes}
							src={src}
							width={imageWidth}
						/> : <LazyImage
							src={src}
							srcSet={srcSet}
							sizes={sizes}
							triggerLoad={false}
							width={imageWidth}
							height={imageHeight}
						/>
				}
			</ConditionalWrapper>
			{renderCaption(title, titleClass, titleHeaderTag, caption, linkText, linkClass)}
		</a>
	);
};

export {
	StaticSliderImageTextLink,
};
