import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useMemo } from 'react';

import { isOnServer } from '~/global/global.constants';
import { throttle } from '~/util/throttle';

import styles from '#/mobile/global/header.module.scss';

export const HeaderMobileMenuButton = observer((props) => {
	const {
		className = '',
		clickHandler,
		dataQA = '',
		headerMobile: {
			isMenuOpen,
		},
		id = '',
		label = '',
	} = props;

	const bodyClickHandler = (event) => {
		if (isMenuOpen && event.target.classList.contains('blockOverlay')) {
			clickHandler();
		}
	};

	const throttledClickHandler = useMemo(() => throttle(() => {
		clickHandler();
	}, 300), []);

	useEffect(() => {
		if (!isOnServer) {
			// work around menu.js preventing default on the click event
			// this keeps us from getting out of sync with the menu being shown
			document
				.querySelector('body')
				.addEventListener('mousedown', bodyClickHandler);
			document
				.querySelector('body')
				.addEventListener('touchstart', bodyClickHandler);
		}

		return function () {
			document
				.querySelector('body')
				.removeEventListener('mousedown', bodyClickHandler);
			document
				.querySelector('body')
				.removeEventListener('touchstart', bodyClickHandler);
			if (throttledClickHandler && throttledClickHandler.cancel) {
				throttledClickHandler.cancel();
			}
		};
	}, []);

	return (
		<button
			aria-label={label}
			className={classNames(className, {
				'tw-hidden-for-print': true,
				[styles['header-mobile-menu-button']]: true,
				[styles['header-mobile-menu-button-open']]: isMenuOpen,
				'has-focus': isMenuOpen,
				'has-menu': true
			})}
			data-tr-link-event-track={false}
			data-qa={dataQA}
			data-button={id}
			onClick={throttledClickHandler}
		>
			<div className={styles['icon-burger']}>
				<span className={styles['burger-patty']} />
				<span className={styles['burger-patty']} />
				<span className={styles['burger-patty']} />
			</div>
		</button>
	);
});
