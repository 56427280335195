import React from 'react';
import ReactMarkdown from 'react-markdown';

import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';
import { LazyImage } from '~/components/static-slider/Components/LazyImage';
import { StaticSliderLink } from '~/components/static-slider/Components/StaticSliderLink';
import { MagicHeaderTag } from '~/components/magic-header';

const ParagraphRenderer = (props) => {
	const {
		children,
	} = props;

	return <p className="tw-text-gray">{children}</p>;
};

const renderCaption = (title, titleClass, titleHeaderTag, caption, links, linkClass, linkColor, linksStacked, linksStackedSmOnly) => {
	return (
		<>
			{title && (
				<MagicHeaderTag
					headerLevel={titleHeaderTag}
					className={titleClass || 'Heading Heading--4'}
				>
					{title}
				</MagicHeaderTag>
			)}
			<ReactMarkdown components={{
				p: ParagraphRenderer
			}}>
				{caption}
			</ReactMarkdown>
			{links &&
				links.map((link, index) => {
					return (
						<StaticSliderLink key={index} link={link} linkClass={linkClass} linkColor={linkColor} linksStacked={linksStacked} linksStackedSmOnly={linksStackedSmOnly} />
					);
				})}
		</>
	);
};

const StaticSliderImageTextLinks = ({ item, trLinkEventCompPosition = null }) => {
	const {
		src,
		srcSet,
		sizes,
		title = null,
		titleClass = '',
		titleHeaderTag = 'h4',
		caption,
		links = [],
		imageClasses = null,
		linkClass,
		linkColor,
		linksStacked,
		linksStackedSmOnly,
	} = item;

	return (
		<div data-tr-link-event-comp-position={trLinkEventCompPosition}>
			<ConditionalWrapper
				condition={imageClasses}
				wrapper={children => <div className={imageClasses}>{children}</div>}>
				<LazyImage
					src={src}
					srcSet={srcSet}
					sizes={sizes}
					triggerLoad={false}
				/>
			</ConditionalWrapper>
			{renderCaption(title, titleClass, titleHeaderTag, caption, links, linkClass, linkColor, linksStacked, linksStackedSmOnly)}
		</div>
	);
};

export {
	StaticSliderImageTextLinks,
};
