import React from 'react';

import { StaticSliderCallanFiveStar } from '~/components/static-slider/Renderers/StaticSlider-callanFiveStar';
import { StaticSliderImageText } from '~/components/static-slider/Renderers/StaticSlider-imageText';
import { StaticSliderImageLink } from '~/components/static-slider/Renderers/StaticSlider-imageLink';
import { StaticSliderImageTextLink } from '~/components/static-slider/Renderers/StaticSlider-imageTextLink';
import { StaticSliderImageTextLinks } from '~/components/static-slider/Renderers/StaticSlider-imageTextLinks';
import { StaticSliderTopBaseSTR } from '~/components/static-slider/Renderers/StaticSlider-topBaseSTR';
import { StaticSliderImageTitleText } from '~/components/static-slider/Renderers/StaticSlider-imageTitleText';
import { StaticSliderImageTitleList } from '~/components/static-slider/Renderers/StaticSlider-imageTitleList';
import { StaticSliderUrbanWood } from '~/components/static-slider/Renderers/StaticSlider-urbanWood';
import { StaticSliderCustom } from '~/components/static-slider/Renderers/StaticSlider-custom';
import { StaticSliderTabbedShoppingLink } from '~/components/static-slider/Renderers/StaticSliderTabbedShoppingLink';

const renderers = {
	imageText: StaticSliderImageText,
	imageLink: StaticSliderImageLink,
	imageTextLink: StaticSliderImageTextLink,
	imageTextLinks: StaticSliderImageTextLinks,
	callanFiveStar: StaticSliderCallanFiveStar,
	topBaseSTR: StaticSliderTopBaseSTR,
	imageTitleText: StaticSliderImageTitleText,
	imageTitleList: StaticSliderImageTitleList,
	urbanWood: StaticSliderUrbanWood,
	custom: StaticSliderCustom,
	tabbedShoppingLink: StaticSliderTabbedShoppingLink
};

class StaticSlider extends React.Component {
	render() {
		const {
			items,
			classes,
			trLinkEventCompName = null,
			trLinkEventCompType = 'static slider',
			width,
			height,
			useS7Image = false,
		} = this.props;
		const ContentRenderer = renderers[this.props.renderer];
		return (
			<div className="ScrollingContainer" data-tr-link-event-comp-type={trLinkEventCompType} data-tr-link-event-comp-name={trLinkEventCompName}>
				<ul className={classes} data-feature-scrollable={true}>
					{items.map((item, index) => {
						return (
							<li key={index}>
								<ContentRenderer trLinkEventCompPosition={`${index + 1}:${items.length}`} item={item} imageWidth={width} imageHeight={height} useS7Image={useS7Image} />
							</li>
						);
					})}
				</ul>
			</div>
		);
	}
}

export {
	StaticSlider,
};
