import React from 'react';
import ReactMarkdown from 'react-markdown';

import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';
import { LazyImage } from '~/components/static-slider/Components/LazyImage';
import { S7Image } from '~/components/Images/S7Image';

const LinkRenderer = (props) => {
	const {
		href,
		children,
	} = props;
	const parsedHref = href.split('?')[0];
	if (href.includes('target=_blank')) {
		return <a className="tw-inline" href={parsedHref} target="_blank" rel="noreferrer">{children}</a>;
	}
	return <a className="tw-inline" href={parsedHref}>{children}</a>;
};

const renderCaption = (titleClass, title, captionClass, caption) => {
	const captionLink = <ReactMarkdown
		components={{
			a: LinkRenderer,
			p: ({ children }) => <p className={captionClass || 'Body Body--2 tw-text-gray'}>{children}</p>
		}}
	>
		{caption}
	</ReactMarkdown>;

	return (
		<>
			{title &&
				<h3 className={titleClass || 'Heading Heading--4 tw-text-gray'}>{title}</h3>
			}
			{caption && captionLink}
		</>
	);
};

const StaticSliderImageTitleText = (props) => {
	const {
		item: {
			titleClass = null,
			title = null,
			captionClass = null,
			caption,
			sizes = null,
			src,
			srcSet = '',
			imageWidth = '',
			imageHeight = '',
			imageClasses = null,
		},
		useS7Image = false,
	} = props;
	return (
		<div>
			<ConditionalWrapper
				condition={imageClasses}
				wrapper={children => <div className={imageClasses}>{children}</div>}>
				{useS7Image ?
					<S7Image
						alt=""
						height={imageHeight}
						src={src}
						width={imageWidth}
					/>
					:
					<LazyImage
						src={src}
						srcSet={srcSet}
						sizes={sizes}
						triggerLoad={false}
					/>
				}
			</ConditionalWrapper>
			{renderCaption(titleClass, title, captionClass, caption)}
		</div>
	);
};

export {
	StaticSliderImageTitleText,
};
