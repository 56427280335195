import React from 'react';

export const AccessibilityLinks = () => {
	return (
		<div className="tw-hidden-for-print">
			<a tabIndex="0" className="tw-sr-only tw-hidden-for-print focus:tw-not-sr-only" href="/accessibility/">Accessibility Statement</a>
			<a href="#main-content" id="skip-nav-link" tabIndex="0" className="tw-sr-only tw-hidden-for-print focus:tw-not-sr-only">Skip Navigation</a>
		</div>
	);
};
