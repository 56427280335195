import React from 'react';

const StaticSliderCustom = ({ item }) => {
	const {
		content,
		contentClass = '',
	} = item;
	return (
		<div className={contentClass}>
			{content}
		</div>
	);
};

export {
	StaticSliderCustom,
};
