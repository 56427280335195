import React from 'react';

import { S7Image } from '~/components/Images/S7Image';

import subMenuStyles from '#/general/header/subMenuPromos.module.scss';

const isMenuHidden = menu => menu.hiddenOnFull;

export const TopMenuSectionPromoItems = (props) => {
	const {
		promoClasses = '',
		menuPromoItems = [],
	} = props;

	const totalMenuItems = menuPromoItems.length;
	return (
		<div
			className={`${subMenuStyles['subMenuPromos']} ${promoClasses}`}
			data-qa="promo-images"
			data-tr-link-event-comp-name="featured content"
		>
			{ menuPromoItems.map((menuPromoItem, index) => {
				const {
					heightLg: menuPromoItemHeight = 0,
					id: menuPromoItemId,
					linkText: menuPromoItemTitle,
					srcLg: menuPromoItemSrc,
					url: menuPromoItemUrl,
					widthLg: menuPromoItemWidth = 0,
				} = menuPromoItem;

				return !isMenuHidden(menuPromoItem)
				// Needs a title for the key since there are duplicate IDs for some reason.
					? <a
						key={menuPromoItemId + menuPromoItemTitle}
						href={menuPromoItemUrl}
						data-tr-link-event-comp-position={`${index + 1}:${totalMenuItems}`}
					>
						<S7Image
							alt=""
							height={menuPromoItemHeight}
							sizes={`(max-width: 900px) 100vw, ${menuPromoItemWidth}px`}
							src={menuPromoItemSrc}
							width={menuPromoItemWidth}
						/>
						<span className={subMenuStyles.subMenuPromos_title}>{menuPromoItemTitle}</span>
					</a>
					: null;
			})}
		</div>
	);
};
