'use client';

import React from 'react';
import dynamic from 'next/dynamic';

import { devBeamEnabled } from '~/global/global.constants';

const DevBeam = dynamic(
	() => import('~/dev-beam/Components/DevBeam').then(mod => mod.DevBeam),
);
const DevBeamProvider = dynamic(
	() => import('~/dev-beam/Contexts/DevBeam.context').then(mod => mod.DevBeamProvider),
);

interface Props {
	className?: string
	mode?: 'MOBILE' | 'DESKTOP'
	show?: boolean
}
export const DevBeamWrapper = (props: Props) => {
	const {
		className = 'tw-hidden md:tw-block print:tw-hidden',
		mode = 'DESKTOP',
	} = props;
	if (!devBeamEnabled) {
		return null;
	}
	return (
		<DevBeamProvider>
			<DevBeam mode={mode} className={className} />
		</DevBeamProvider>
	);
};

// eslint-disable-next-line import/no-default-export
export default DevBeamWrapper;
