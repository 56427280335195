import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

import { TextLinkColor } from '~/components/Buttons/Types/constants';
import { buildTextLinkClassNames } from '~/components/Buttons/Utils/Button.utils';
import styles from '~/components/Buttons/Components/LinkBlock.module.scss';

export interface IProps {
	className?: string,
	color?: TextLinkColor;
	hasNoUnderline?: boolean;
}

export const LinkBlockTextLink = (props: PropsWithChildren<IProps>) => {
	const className = buildTextLinkClassNames(props.color, props.hasNoUnderline);

	return (
		<span className={classNames(`${className} ${styles.linkBlockTextLink} ${props.className}`, {
			[styles.linkWithUnderlineOnHover]: props.hasNoUnderline,
		})}>
			{props.children}
		</span>
	);
};
