import React from 'react';

import { ConditionalWrapper } from '~/components/conditional-wrapper/ConditionalWrapper';
import { S7Image } from '~/components/Images/S7Image';
import { LazyImage } from '~/components/static-slider/Components/LazyImage';

const renderCaption = (linkText) => {
	return (
		<>
			{linkText && <span className="tw-text-gray tw-font-semibold">{linkText}</span>}
		</>
	);
};

const StaticSliderImageLink = ({ item, trLinkEventCompPosition = null, useS7Image = false }) => {
	const {
		height = 0,
		imageClasses = null,
		link,
		linkText = null,
		src,
		srcSet,
		sizes,
		width = 0,
	} = item;
	return (
		<a className="tw-no-underline"
			data-tr-link-event-comp-position={trLinkEventCompPosition}
			data-tr-link-event-name={linkText}
			href={link}>
			<ConditionalWrapper
				condition={imageClasses}
				wrapper={children => <div className={imageClasses}>{children}</div>}
			>
				{
					useS7Image
						? <S7Image
							alt=""
							height={height}
							sizes={sizes}
							src={src}
							width={width}
						/> : <LazyImage
							src={src}
							srcSet={srcSet}
							sizes={sizes}
							triggerLoad={false}
						/>
				}
			</ConditionalWrapper>
			{renderCaption(linkText)}
		</a>
	);
};

export {
	StaticSliderImageLink,
};
