import React from 'react';

import { s7ContentPath } from '~/global/global.constants';
import { MagicHeaderTag } from '~/components/magic-header';

const renderImage = (imageClass, src, width, height) => {
	return (
		<img alt="" className={imageClass} src={`${s7ContentPath}/${src}`} width={width} height={height} />
	);
};

const renderTextTop = (textTop) => {
	return (
		<span className="tw-block tw-mb-1">{textTop}</span>
	);
};

const renderText = (textEmphasis = '', textEmphasisClass = '', text, textFootnote = '', textFootnoteClass = '',) => {
	return (
		<>
			{textEmphasis && (<span className={textEmphasisClass}>{textEmphasis}</span>)}
			{text}
			{textFootnote && <span className={textFootnoteClass}>{textFootnote}</span>}
		</>
	);
};

export const StaticSliderUrbanWood = ({ item }) => {
	const {
		wrapperClass = '',
		imageClass,
		imageSrc,
		imageWidth,
		imageHeight,
		title = '',
		titleHeaderTag = 'h3',
		titleClass = 'tw-heading-6',
		textTop = '',
		textEmphasis = '',
		textEmphasisClass = '',
		text,
		textFootnote = '',
		textFootnoteClass = '',
	} = item;
	return (
		<div className={wrapperClass}>
			{title &&
				<MagicHeaderTag
					headerLevel={titleHeaderTag}
					className={titleClass || 'Heading Heading--4'}
				>{title}</MagicHeaderTag>
			}
			{textTop && renderTextTop(textTop)}
			{renderImage(imageClass, imageSrc, imageWidth, imageHeight)}
			{renderText(textEmphasis, textEmphasisClass, text, textFootnote, textFootnoteClass)}
		</div>
	);
};
