import React, { ComponentProps, PropsWithChildren } from 'react';
import classNames from 'classnames';

import { LinkBase } from './LinkBase';
import styles from './LinkBlock.module.scss';

export interface IProps extends ComponentProps<typeof LinkBase> {

}

export const LinkBlock = (props: PropsWithChildren<IProps>) => {
	const { className = '', ...anchorProps } = props;

	return (
		<LinkBase
			{...anchorProps}
			className={classNames({
				[className]: className,
				[styles.linkBlock]: true,
			})}
		>
			{props.children}
		</LinkBase>
	);
};
