'use client';

import { useEffect } from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';

export const useLinkEvent = () => {
	const { linkEventStore } = useGlobalContext();

	useEffect(() => {
		linkEventStore.init();
		return linkEventStore.cleanup;
	}, []);
	return linkEventStore;
};
