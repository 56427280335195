import React from 'react';

import { HomepageLinkItem } from '~/components/homepage-link-list/homepage-link-item/HomepageLinkItem';
import { IHomepageLinkItem } from '~/components/homepage-link-list/HomepageLinkList.type';
import { S7Image } from '~/components/Images/S7Image';
import styles from '~/components/tabbed-shopping/tabbed-shopping-link/TabbedShoppingLink.module.scss';

interface IProps extends IHomepageLinkItem {
	trLinkEventCompPosition?: string;
}

export const TabbedShoppingLink = (props: IProps) => {
	return (
		<HomepageLinkItem
			caption={props.caption}
			captionClassName={styles.caption}
			href={props.href}
			trLinkEventCompPosition={props.trLinkEventCompPosition}
			trLinkEventCompType="tabbed shopping"
		>
			<div className={styles.link}>
				{props.imageSrc && (
					<S7Image
						alt={''}
						className={styles.image}
						height={props.imageHeight}
						sizes={`(max-width: 900px) 100vw, ${props.imageWidth}px`}
						src={props.imageSrc}
						width={props.imageWidth}
					/>
				)}
			</div>
		</HomepageLinkItem>
	);
};
