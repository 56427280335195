import { observer } from 'mobx-react';
import React from 'react';

import { HeaderMobileSearchButton } from '~/global/header-mobile/Components/HeaderMobileSearchButton';
import { HeaderMobileSearchForm } from '~/global/header-mobile/Components/HeaderMobileSearchForm';

import styles from '#/mobile/global/header.module.scss';

const HeaderMobileSearch = observer(({ headerMobile = {}, searchButtonClickHandler }) => {
	return (
		<div className={`${styles['header-mobile-search']} tw-hidden-for-print`}>
			<HeaderMobileSearchButton
				clickHandler={searchButtonClickHandler}
				headerMobile={headerMobile}
			/>
			<HeaderMobileSearchForm headerMobile={headerMobile} />
		</div>
	);
});

export { HeaderMobileSearch };
