'use client';

import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';

import styles from '#/mobile/global/header.module.scss';

export const HeaderMobileSearchForm = observer((props = {}) => {
	const {
		headerMobile: {
			isSearchOpen,
			showSearchShadow,
		}
	} = props;

	const {
		pageStore: {
			isMediaQueryMd = false,
		} = {},
	} = useGlobalContext();

	const [rfkId, setRfkId] = useState(null);

	useEffect(() => {
		// hang on to the initial value - rfk forces us to lock into full or mobile widget, it can't render both
		if (!isMediaQueryMd) {
			setRfkId('rfkid_6');
		}
	}, []);

	return (
		<section
			className={
				classNames({
					[styles['header-mobile-search-form-container']]: true,
					[styles['header-mobile-search-form-container-shadow']]: showSearchShadow,
				})
			}
			id="page-search"
			style={{ display: isSearchOpen ? 'block' : 'none' }}
			suppressHydrationWarning
		>
			<form
				action={'/search'}
				className="header-mobile-search-form"
				method="get"
			>
				<span className="tw-sr-only">When typing in this field, a list of search results will appear and be automatically updated as you type.</span>
				<div
					className={styles['header-mobile-search-form-field']}
					suppressHydrationWarning
				>
					<label
						className="tw-sr-only"
						htmlFor="search-input"
					>
						Search
					</label>
					<input
						className={styles['header-mobile-search-input']}
						id="search-input"
						name="query"
						type="search"
						placeholder="What are you looking for?"
						{
							...(rfkId) && {
								'data-rfkid': rfkId,
							}
						}
					/>
					<button
						id="search-submit"
						className={styles['header-mobile-search-submit']}
					>
						Go
					</button>
				</div>
			</form>
		</section>
	);
});
