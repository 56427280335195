import { observer } from 'mobx-react';
import React from 'react';

import menuStyles from '#/mobile/global/menu.module.scss';

import { s7ContentPath } from '~/global/global.constants';

import { StaticSlider } from '~/components/static-slider/StaticSlider';

const TopMenuMobilePromoSlider = observer((props) => {
	const {
		title = '',
		url = '',
		contentPromos = [],
	} = props;

	return (
		<div className={menuStyles.promoSlider} data-qa="promo-images" data-tr-link-event-comp-name="featured content">
			<div className={menuStyles.promoSliderHeader}>
				<span className={`${menuStyles.promoSliderTitle} Heading Heading--4`} data-qa="promo-slider-header">{title}</span>
				<a
					className={menuStyles.promoSliderShopLink}
					data-tr-link-event-comp-name={title}
					href={url}>
					<span aria-label={`Shop All ${title}`}>Shop All</span>
					<span aria-hidden="true">
						<img src={`${s7ContentPath}/icon_forwardSecondarySubmenu_gray`} width="6" alt="" />
					</span>
				</a>
			</div>
			<div className={menuStyles.promoSliderHolder}>
				<StaticSlider
					trLinkEventCompName="featured content"
					trLinkEventCompType="header navigation"
					items={contentPromos}
					classes="ScrollingContainer-maxWidth--250 tw-mb-0"
					renderer="imageLink"
					useS7Image
				/>
			</div>
		</div>
	);
});

export { TopMenuMobilePromoSlider };
