export const CartAnchorPlaceholder = () => {
	return (
		<a
			href="/cart"
			className="tw-block tw-relative tw-leading-[0] tw-h-5 md:tw-w-[34px] md:tw-h-[27px] top-link"
			data-tr-link-event-comp-name="top links"
			data-tr-link-event-name="cart icon"
			data-qa="cart-items-status-link"
			data-track
			title="Cart"
		>

			<span className="tw-sr-only">Cart</span>
		</a>
	);
};

// eslint-disable-next-line import/no-default-export
export default CartAnchorPlaceholder;
