import React, { ElementType } from 'react';
import classNames from 'classnames';

import styles from '~/components/homepage-link-list/homepage-link-caption/HomepageLinkCaption.module.scss';

interface IProps {
	caption: string;
	captionClassName?: string;
	captionComponent?: ElementType;
	captionSize?: 'lg' | 'xl';
}

export const HomepageLinkCaption = (props: IProps) => {
	const { captionClassName = '', captionSize = 'lg' } = props;
	const Element = props.captionComponent || 'span';

	return (
		<Element
			className={classNames(styles.caption, {
				[styles.captionxl]: captionSize === 'xl',
				[captionClassName]: captionClassName,
			})}
			style={{ fontWeight: 300 }}
		>
			{props.caption}
		</Element>
	);
};
